import Grid from "@mui/material/Unstable_Grid2";
import dayjs, {Dayjs} from "dayjs";
import React from "react";
import {DatePicker as MuiDatePicker} from "@mui/x-date-pickers";
import {toLocalDate} from "../time/DateUtils";

/**
 * It creates "2015-01-05" type date strings
 */
export function DatePicker(
  props: {
    xs       : number,
    value    : string | undefined,
    label    : string,
    onChange : (newValue: string | undefined) => void,
  }
) {

  return (
    <Grid xs={props.xs}>
      <MuiDatePicker<Dayjs>
        label={props.label}
        value={props.value !== undefined ? dayjs(props.value) : null }
        slotProps={{ textField: { size: "small" }, openPickerButton: {size: "small" }}}
        onChange={e => props.onChange(dayjsToIsoString(e)) }
      />
    </Grid>
  )
}

function dayjsToIsoString(day: Dayjs | null) {
  if (day === null) {
    return undefined
  } else {
    return toLocalDate(day.toDate())
  }
}
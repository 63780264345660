import React from "react"
import {toLocalDate} from "./DateUtils"

export function ClockProvider({children}: { children: React.ReactNode }) {
  const [state, setState] = React.useState<Clock>()

  React.useEffect(() => {
    if (state === undefined) {
      setState(createNewClock())
    } else {
      const timeout = setTimeout(_ => { setState(createNewClock()) }, ONE_MINUTE_IN_MS)

      return () => clearTimeout(timeout)
    }
  }, [state, setState])

  return state === undefined
    ? <></>
    : <ClockContext.Provider value={state}>
        {children}
      </ClockContext.Provider>
    
}

const ONE_MINUTE_IN_MS = 60 * 1000
const createNewClock = () => {
  const currentDate = new Date()
  const currentDay  = toLocalDate(currentDate)
  const currentTime = currentDate.getTime()

  return ({ currentDay, currentTime })
}

const ClockContext = React.createContext<Clock>(null!)
interface Clock {
  currentDay  : string,
  currentTime : number,
}

export function useClock() {
  return React.useContext(ClockContext)
}
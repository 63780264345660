import React, {ReactNode} from "react"
import {Button, Tooltip} from "@mui/material"
import AddCircleIcon from "@mui/icons-material/AddCircle"
import CancelIcon from "@mui/icons-material/Cancel"
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import DeleteIcon from "@mui/icons-material/Delete"
import RestoreFromTrashIcon from "@mui/icons-material/Delete"

type ButtonProps = {
  tooltip  : string,
  onClick  : () => void,
  disabled?: boolean,
}

export function CancelButton(props: ButtonProps) {
  return <GenericButton {...props} label="Cancel" variant="outlined" startIcon={<CancelIcon/>}/>
}

export function ArchiveButton(props: ButtonProps) {
  return <GenericButton {...props} label="Archive" variant="outlined" startIcon={<DeleteIcon/>} color="error"/>
}

export function UnarchiveButton(props: ButtonProps) {
  return <GenericButton {...props} label="Unarchive" variant="outlined" startIcon={<RestoreFromTrashIcon/>} color="error"/>
}

export function CreateButton(props: ButtonProps) {
  return <GenericButton {...props} label="Create" variant="contained" startIcon={<AddCircleIcon/>}/>
}

export function UpdateButton(props: ButtonProps) {
  return <GenericButton {...props} label="Update" variant="contained" startIcon={<CheckCircleIcon/>}/>
}

// confirm dialogs
export function YesButton(props: ButtonProps) {
  return <GenericButton {...props} label="Yes, I'm sure" variant="contained" startIcon={<CheckCircleIcon/>}/>
}

export function NoButton(props: ButtonProps) {
  return <GenericButton {...props} label="No, cancel" variant="outlined" startIcon={<CancelIcon/>}/>
}

function GenericButton(
  props: ButtonProps & {
    label     : string,
    variant   : "contained" | "outlined",
    startIcon : ReactNode,
    color?    : "error"
  },
) {
  return (
    <Tooltip title={props.tooltip} enterDelay={1000} arrow>
      <span>
        <Button
          color={props.color}
          disabled={props.disabled}
          onClick={props.onClick}
          variant={props.variant}
          startIcon={props.startIcon}
        >{props.label}</Button>
      </span>
    </Tooltip>
  )
}
const ONE_MINUTE_IN_MS = 60 * 1000
const ONE_DAY_IN_MS    = 1000 * 60 * 60 * 24
export function toLocalDate(date: Date) {
  const utc  = new Date(date.getTime() - (date.getTimezoneOffset() * ONE_MINUTE_IN_MS))
  return utc.toISOString().substring(0, 10)
}

export function toAgeString(time: Date, currentTime: Date) {
  const previousDateTime = time.getTime()
  const currentDateTime  = currentTime.getTime()
  const ageInDays = Math.floor((currentDateTime - previousDateTime) / ONE_DAY_IN_MS)
  if (ageInDays > 0) { // it happened more than 1 day ago
    if (ageInDays < 8) {
      return ageInDays === 1 ? 'Yesterday' : `${ageInDays} days ago`
    } else if (ageInDays < 15) {
      return 'Last week'
    } else {
      return toLocalDate(time)
    }
  } else { // it happened today
    const ageInMinutes = Math.floor((currentDateTime - previousDateTime) / ONE_MINUTE_IN_MS)
    if (ageInMinutes < 2) {
      return "Just now"
    } else if (ageInMinutes < 60) {
      return `${ageInMinutes} minutes ago`
    } else {
      const ageInHours = Math.floor(ageInMinutes / 60)
      return ageInHours === 1 ? '1 hour ago' : `${ageInHours} hours ago`
    }
  }
}
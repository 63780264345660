import {useParams} from "react-router-dom"
import {Page} from "../../../components/Page"
import {ContentStore, useContentStore} from "../../ContentStoreProvider"
import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormControlLabel,
  IconButton,
  Paper,
  Skeleton,
  Stack,
  Tab,
  Tabs,
  TextField
} from "@mui/material"
import AddIcon from '@mui/icons-material/Add'
import {DateTimePicker, DateTimeValidationError, PickerChangeHandlerContext} from "@mui/x-date-pickers"
import React, {useState} from "react"
import dayjs, {Dayjs} from "dayjs"
import SaveIcon from "@mui/icons-material/Save"
import PublishIcon from "@mui/icons-material/Publish"
import CancelIcon from "@mui/icons-material/Cancel"
import {PublishContentDialog} from "./dialogs/PublishContentDialog"
import Models from "../../api/ContentModels"
import {isEqualIgnoreUndefined} from "../../../support/Utils"
import {UnpublishContentDialog} from "./dialogs/UnpublishContentDialog"
import {useSnackbar} from "../../../common/SnackbarProvider"
import {MarkdownEditor} from "../../../common/MarkdownEditor"
import {ArchiveContentDialog} from "./dialogs/ArchiveContentDialog"
import {UnarchiveContentDialog} from "./dialogs/UnarchiveContentDialog"
import Alert from "@mui/material/Alert"
import {NewTaskDialog} from "../../../task/component/NewTaskDialog"
import {useTaskStore} from "../../../task/TaskStoreProvider"
import {TaskListItem} from "../../../task/component/TaskListItem"
import {sortBy} from "lodash"
import DeleteIcon from "@mui/icons-material/Delete"
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import {useClock} from "../../../common/time/ClockProvider"
import Typography from "@mui/material/Typography"
import UpdateContentCommand = Models.UpdateContentCommand

type MutateFn<T> = (t: T | undefined) => Partial<Models.ContentState>
type HtmlInputChangeHandler<T> = (event: React.ChangeEvent<T>) => void
type DateTimeChangeHandler = (dayjs: Dayjs | null, _: PickerChangeHandlerContext<DateTimeValidationError>) => void

type ContentEditPageState = {
  isAnythingChanged:     boolean
  currentForm:           Partial<Models.ContentState>
  original:              Models.ContentState
  handleBooleanChange:   (mutate: MutateFn<boolean>)               => HtmlInputChangeHandler<HTMLInputElement>
  handleStringChange:    (mutate: MutateFn<string>)                => HtmlInputChangeHandler<HTMLInputElement | HTMLTextAreaElement>
  handleStringChange2:   (mutate: MutateFn<string>)                => (newValue: string) => void
  handleStringSetChange: (mutate: MutateFn<ReadonlyArray<string>>) => HtmlInputChangeHandler<HTMLInputElement>
  handleInstantChange:   (mutate: MutateFn<number>)                => DateTimeChangeHandler
}

export function ContentEditPage() {
  const { contentId } = useParams()
  const contentStore  = useContentStore()

  const content = contentStore.contentsById[contentId!]
  return (
    <Page title="Edit">
      { content
        ? <ContentEditComponent contentId={contentId!} contentStore={contentStore}/>
        : <LoadingEditComponent/>
      }
    </Page>
  )
}

function ContentEditComponent(props: {contentId: string, contentStore: ContentStore}) {
    const content = props.contentStore.contentsById[props.contentId];

    const [isAnythingChanged, setIsAnythingChanged] = React.useState(false);
    const [contentForm,       setContentForm]       = React.useState<Partial<Models.ContentState>>(content!);

    const handleStringChange = React.useCallback((mutate: MutateFn<string>) => {
      return (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const str = event.target.value.length === 0 ? undefined : event.target.value
        const newForm = mutate(str)
        setContentForm(oldForm => ({ ...oldForm, ...newForm }))
      }
    }, [setContentForm])

    const handleStringChange2 = React.useCallback((mutate: MutateFn<string>) => {
      return (newValue: string) => {
        const str = newValue.length === 0 ? undefined : newValue
        const newForm = mutate(str)
        setContentForm(oldForm => ({ ...oldForm, ...newForm }))
      }
    }, [setContentForm])

    const handleStringSetChange = React.useCallback((mutate: MutateFn<ReadonlyArray<string>>) => {
      return (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const str = event.target.value.length === 0 ? undefined : event.target.value
        let newForm: Partial<Models.ContentState>
        if (str === undefined) {
          newForm = mutate(undefined)
        } else {
          const parts = str.split(",").map(s => s.trim()).filter(p => p !== "")
          newForm = mutate(parts)
        }
        setContentForm(oldForm => ({ ...oldForm, ...newForm }))
      }
    }, [setContentForm])

    const handleBooleanChange = React.useCallback((mutate: MutateFn<boolean>) => {
      return (event: React.ChangeEvent<HTMLInputElement>) => {
        const bool = event.target.checked
        const newForm = mutate(bool)
        setContentForm(oldForm => ({ ...oldForm, ...newForm }))
      }
    }, [setContentForm])

    const handleInstantChange = React.useCallback((mutate: MutateFn<number>) => {
      return (dayjs: Dayjs | null, _: PickerChangeHandlerContext<DateTimeValidationError>) => {
        const instant = dayjs === null ? undefined : dayjs.valueOf()
        const newForm = mutate(instant)
        setContentForm(oldForm => ({ ...oldForm, ...newForm }))
      }
    }, [setContentForm])

    const state: ContentEditPageState = {
      isAnythingChanged     : isAnythingChanged,
      currentForm           : contentForm,
      original              : content,
      handleStringChange    : handleStringChange,
      handleStringChange2   : handleStringChange2,
      handleStringSetChange : handleStringSetChange,
      handleInstantChange   : handleInstantChange,
      handleBooleanChange   : handleBooleanChange,
    };

    React.useEffect(() => {
      setIsAnythingChanged(!isEqualIgnoreUndefined(content, contentForm));
    }, [content, contentForm, setIsAnythingChanged]);

    React.useEffect(() => {
      setContentForm(content);
    }, [content]);

    if(content === undefined) {
        return <div>Content Not Found For Id: {props.contentId}</div>
    }

    const mainComponent = (
      <Stack spacing={2} direction="row">
        <ContentMarkdownEditor  {...state}/>
        <ContentSettingsSideBar {...state}/>
      </Stack>
    );

    return state.original.blackListedDomainWarnings && state.original.blackListedDomainWarnings.length > 0
      ? (
        <Stack spacing={2}>
          <Alert severity="warning" variant="filled">{`Article is using black listed domains: ${state.original.blackListedDomainWarnings.join(", ")}`}</Alert>
          {mainComponent}
        </Stack>
      )
      : mainComponent;
}

function LoadingEditComponent() {
  return (
    <Stack spacing={2} direction="row">
      <Box width="100%">
        <Skeleton variant="rounded" height="50em"/>
      </Box>
      <Box width="512px">
        <Skeleton variant="rounded" height="50em" width="512px"/>
      </Box>
    </Stack>
  )
}

const ContentSettingsSideBar = (props: ContentEditPageState) => {
  const [value, setValue] = useState<number>(0)

  return (
    <Paper elevation={4}>
      <Box>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={(_, newValue) => setValue(newValue)}>
            <Tab label="Settings"/>
            <Tab label="Advanced settings"/>
            <Tab label="Tasks"/>
          </Tabs>
        </Box>

        <Box width="400px" p="16px">
          <SettingsPanel         {...props} hidden={value !== 0}/>
          <AdvancedSettingsPanel {...props} hidden={value !== 1}/>
          <TasksPanel            {...props} hidden={value !== 2}/>
        </Box>
      </Box>
    </Paper>
  )
}

const ContentMarkdownEditor = (props: ContentEditPageState) => {
  return React.useMemo(() => {
    return (
      <Box flexGrow={1}>
        <Paper sx={{padding: "0px"}} elevation={2}>
          <MarkdownEditor
            onChange={props.handleStringChange2(string => ({markdownString: string}))}
            initialValue={props.original.markdownString}
          />
        </Paper>
      </Box>
    );
  }, [props.handleStringChange2, props.original]) // this must stay here, otherwise the editor is not working
}

const TasksPanel = (props: ContentEditPageState & { hidden: boolean }) => {
  const [isNewTaskDialog, setIsNewTaskDialog] = useState(false)
  const clock     = useClock()
  const taskStore = useTaskStore()
  const snackbar  = useSnackbar()

  const tasksForContent = taskStore.tasksByContentId[props.original.id] || []
  const tasksToDisplay  = sortBy(tasksForContent, task => task.created)

  return (
    <Box hidden={props.hidden}>
      { tasksToDisplay.length > 0
        ? <Stack spacing={1}>
          { tasksToDisplay.map(task => <TaskListItem key={task.id} task={task} taskStore={taskStore} snackBar={snackbar} currentDay={clock.currentDay}/>)}
        </Stack>
        : <Typography variant="body1">There are no tasks assigned to this content</Typography>
      }
      <Stack direction="row" spacing={2} mt={"1.5em"}>
        <Button variant="contained" color="primary" startIcon={<AddIcon/>} onClick={(e) => { setIsNewTaskDialog(true); e.currentTarget.blur(); }}>Create New Task</Button>
      </Stack>
      <NewTaskDialog isOpen={isNewTaskDialog} setIsOpen={setIsNewTaskDialog} contentId={props.original.id}/>
    </Box>
  )
}

const SettingsPanel = (props: ContentEditPageState & {hidden: boolean}) => {

  return (
    <Box hidden={props.hidden}>
      <Stack spacing={2}>
        <LiveUrlLink {...props}/>
        <Stack spacing={2} direction="row">
          <StatusIndicatorChip {...props}/>
          <TypeField {...props}/>
        </Stack>
        <Stack spacing={2} direction="row">
          <WebsiteField {...props}/>
          <CategoriesField {...props}/>
        </Stack>
        { props.original.publishTime !== undefined && <PublishTimeField {...props}/> }
        <Stack spacing={2} direction="row">
          <IsSponsoredField {...props}/>
          <IsFeaturedField {...props}/>
        </Stack>
        <Stack spacing={2} direction="row">
          <IsAiArticleField {...props}/>
          <DoNotPublishField {...props}/>
        </Stack>
        <ExcerptField {...props}/>
        <RegenerateAiDescriptionButton contentId={props.original.id}/>
        <NotesField {...props}/>
        <Buttons {...props}/>
      </Stack>
    </Box>
  )
}

const LiveUrlLink = (props: ContentEditPageState) => {
  const snackBar     = useSnackbar()

  const contentAbsoluteUrl = props.currentForm.absoluteUrl

  if (contentAbsoluteUrl !== undefined) {
    const onCopyLinkClick = async () => {
      await navigator.clipboard.writeText(contentAbsoluteUrl)
      snackBar.showSuccess("Link copied to clipboard")
    }
    return (
      <Stack spacing={1} direction="row">
        <Button variant="text" onClick={onCopyLinkClick}>Copy live url</Button>
        <Button variant="text" href={contentAbsoluteUrl} target="_blank">Go to live url</Button>
      </Stack>
    )
  }
  return (
    <Stack spacing={1} direction="row">
      <IconButton disabled><ContentCopyIcon fontSize="small"/></IconButton>
      <Button variant="text" disabled>Go to live url</Button>
    </Stack>
  )
}

const AdvancedSettingsPanel = (props: ContentEditPageState & {hidden: boolean}) => {
  return (
    <Box hidden={props.hidden}>
      <Stack spacing={2}>
        <Stack spacing={2} direction="row">
          <InternalTagsField {...props}/>
          <FeaturedImageIdField {...props}/>
        </Stack>
        <Stack spacing={2} direction="row">
          <AuthorIdField {...props}/>
          <DirectoryField {...props}/>
        </Stack>
        <SlugField {...props}/>
        <MetaTitleField {...props}/>
        <MetaDescriptionField {...props}/>
        <JsonLdField {...props}/>
        <Buttons {...props}/>
      </Stack>
    </Box>
  )
}

const Buttons = (props: ContentEditPageState) => {
  return (
    <>
      <Stack spacing={2} direction="row">
        <UpdateButton {...props}/>
        { props.original.publishTime === undefined
          ? <PublishButton   contentId={props.original.id}/>
          : <UnpublishButton contentId={props.original.id}/>
        }
        <Box flexGrow={1}/>
        { props.original.isArchived
          ? <UnarchiveButton contentId={props.original.id}/>
          : <ArchiveButton   contentId={props.original.id}/>
        }
      </Stack>
    </>
  )
}

const stringCompare = (original: string | undefined, form: string | undefined) => {
  const nonEmptyOriginal = original === undefined || original.length === 0 ? undefined : original
  const nonEmptyForm     = form === undefined || form.length === 0 ? undefined : form

  return nonEmptyOriginal === nonEmptyForm
    ? undefined
    : nonEmptyForm
}

const stringSetCompare = (original: ReadonlyArray<string> | undefined, form: ReadonlyArray<string> | undefined) => {
  const nonEmptyOriginal = original === undefined || original.length === 0 ? undefined : original;
  const nonEmptyForm     = form === undefined || form.length === 0 ? undefined : form;

  return nonEmptyOriginal === nonEmptyForm
    ? undefined
    : nonEmptyForm;
};

const boolCompare = (original: boolean | undefined, form: boolean | undefined) => {
  const nonEmptyOriginal = original === undefined || !original ? undefined : original;
  const nonEmptyForm     = form === undefined || !form ? undefined : form;

  return nonEmptyOriginal === nonEmptyForm
    ? undefined
    : (nonEmptyForm || false);
};

const UpdateButton = (props: ContentEditPageState) => {
  const {showError, showSuccess} = useSnackbar();
  const {updateContent} = useContentStore();
  const {original, currentForm} = props;

  const handleUpdate = () => {
    const command: UpdateContentCommand = {
      contentId: original.id,

      authorId:        stringCompare(original.authorId, currentForm.authorId),
      categories:      stringSetCompare(original.categories, currentForm.categories),
      directory:       stringCompare(original.directory, currentForm.directory),
      excerpt:         stringCompare(original.excerpt, currentForm.excerpt),
      featuredImageId: stringCompare(original.featuredImageId, currentForm.featuredImageId),
      internalTags:    stringSetCompare(original.internalTags, currentForm.internalTags),
      isFeatured:      boolCompare(original.isFeatured, currentForm.isFeatured),
      isAiArticle:     boolCompare(original.isAiArticle, currentForm.isAiArticle),
      doNotPublish:    boolCompare(original.doNotPublish, currentForm.doNotPublish),
      isSponsored:     boolCompare(original.isSponsored, currentForm.isSponsored),
      jsonLd1:         stringCompare(original.jsonLd1, currentForm.jsonLd1),
      markdownString:  stringCompare(original.markdownString, currentForm.markdownString),
      metaTitle:       stringCompare(original.metaTitle, currentForm.metaTitle),
      metaDescription: stringCompare(original.metaDescription, currentForm.metaDescription),
      notes:           stringCompare(original.notes, currentForm.notes),
      slug:            stringCompare(original.slug, currentForm.slug),
      typ3:            stringCompare(original.typ3, currentForm.typ3),
      websiteName:     stringCompare(original.websiteName, currentForm.websiteName),
    };

    updateContent(command)
      .then(_ => showSuccess("Content is successfully updated"))
      .catch(_ => showError("Could not update content"));
  };

  return (
    <Button variant="contained" color="primary" disabled={!props.isAnythingChanged} onClick={handleUpdate} startIcon={<SaveIcon/>}>Update</Button>
  );
}

const PublishButton = (props: {contentId: string}) => {
  const [isPublishDialogOpen, setIsPublishDialogOpen] = React.useState(false);

  return (
    <>
      <PublishContentDialog contentId={props.contentId} isOpen={isPublishDialogOpen} setIsOpen={setIsPublishDialogOpen}/>
      <Button variant="contained" color="secondary" startIcon={<PublishIcon/>} onClick={() => {setIsPublishDialogOpen(true)}}>Publish</Button>
    </>
  );
}

const RegenerateAiDescriptionButton = (props: { contentId: string }) => {
  const {createAiDescription} = useContentStore()
  const snackbar = useSnackbar()

  const command: Models.CreateAiDescriptionCommand = {
    contentId: props.contentId,
  }

  const regenerateHandler = () => {
    createAiDescription(command)
      .then(_ => {
        snackbar.showSuccess("AI description generation requested");
      })
      .catch(error => {
        snackbar.showError(`Error: ${error.message}`);
    })
  }

  return (
    <>
      <Button variant="text" color="secondary" onClick={() => {regenerateHandler()}}>Regenerate AI Description</Button>
    </>
  )
}

const UnpublishButton = (props: { contentId: string }) => {
  const [isUnpublishDialogOpen, setIsUnpublishDialogOpen] = React.useState(false);

  return (
    <>
      <UnpublishContentDialog contentId={props.contentId} isOpen={isUnpublishDialogOpen} setIsOpen={setIsUnpublishDialogOpen}/>
      <Button variant="contained" color="secondary" startIcon={<CancelIcon/>} onClick={() => {setIsUnpublishDialogOpen(true)}}>Unpublish</Button>
    </>
  );
}

const UnarchiveButton = (props: { contentId: string }) => {
  const [isUnarchiveDialogOpen, setIsUnarchiveDialogOpen] = React.useState(false);

  return <>
    <UnarchiveContentDialog contentId={props.contentId} isOpen={isUnarchiveDialogOpen} setIsOpen={setIsUnarchiveDialogOpen}/>
    <Button variant="outlined" color="error" startIcon={<RestoreFromTrashIcon/>} onClick={() => {setIsUnarchiveDialogOpen(true)}}>Unarchive</Button>
  </>
}

const ArchiveButton = (props: { contentId: string }) => {
  const [isArchiveDialogOpen, setIsArchiveDialogOpen] = React.useState(false);

  return <>
    <ArchiveContentDialog contentId={props.contentId} isOpen={isArchiveDialogOpen} setIsOpen={setIsArchiveDialogOpen}/>
    <Button variant="outlined" color="error" startIcon={<DeleteIcon/>} onClick={() => {setIsArchiveDialogOpen(true)}}>Archive</Button>
  </>
}

const StatusIndicatorChip = (props: ContentEditPageState) => (
  <Box sx={{height: "100%", width:"100%", display: "flex", alignItems: "center", justifyContent: "center"}}>
    { props.original.publishTime === undefined
      ? <Chip color="warning" label="Draft" sx={{width: "100%"}}/>
      : props.original.publishTime <= Date.now()
        ? <Chip color="success" label="Published" sx={{width: "100%"}}/>
        : <Chip color="info" label="Scheduled" sx={{width: "100%"}}/>
    }
  </Box>
);

const TypeField = (props: ContentEditPageState) => (
  <TextField
    onChange={props.handleStringChange(string => ({typ3: string}))}
    defaultValue={props.original.typ3}
    size="small"
    label="Content type"
    fullWidth
  />
);

const WebsiteField = (props: ContentEditPageState) => (
  <TextField
    onChange={props.handleStringChange(string => ({websiteName: string}))}
    defaultValue={props.original.websiteName}
    size="small"
    label="Website"
    fullWidth
  />
);

const PublishTimeField = (props: ContentEditPageState) => (
  <DateTimePicker
    onChange={props.handleInstantChange(instant => ({publishTime: instant}))}
    defaultValue={dayjs(props.original.publishTime)}
    slotProps={{ textField: { fullWidth: true, size: "small" } }}
    label="Publish time"
    readOnly
  />
);

const CategoriesField = (props: ContentEditPageState) => (
  <TextField
    onChange={props.handleStringSetChange(set => ({categories: set}))}
    defaultValue={props.original.categories.join(", ")}
    size="small"
    label="Categories"
    fullWidth
  />
);

const IsAiArticleField = (props: ContentEditPageState) => (
  <FormControlLabel
    labelPlacement="start"
    control={<Checkbox
      onChange={props.handleBooleanChange(bool => ({isAiArticle: bool}))}
      defaultChecked={props.original.isAiArticle}
      size="small"
    />}
    label="Ai Article"
  />
)

const IsSponsoredField = (props: ContentEditPageState) => (
  <FormControlLabel
    labelPlacement="start"
    control={<Checkbox
      onChange={props.handleBooleanChange(bool => ({isSponsored: bool}))}
      defaultChecked={props.original.isSponsored}
      size="small"
    />}
    label="Sponsored"
  />
)

const IsFeaturedField = (props: ContentEditPageState) => (
  <FormControlLabel
    labelPlacement="start"
    control={<Checkbox
      onChange={props.handleBooleanChange(bool => ({isFeatured: bool}))}
      defaultChecked={props.original.isFeatured}
      size="small"
    />}
    label="Featured"
  />
)

const DoNotPublishField = (props: ContentEditPageState) => (
  <FormControlLabel
    labelPlacement="start"
    control={<Checkbox
      onChange={props.handleBooleanChange(bool => ({doNotPublish: bool}))}
      defaultChecked={props.original.doNotPublish}
      size="small"
    />}
    label="Do Not Publish"
  />
)

const ExcerptField = (props: ContentEditPageState) => (
  <TextField
    onChange={props.handleStringChange(string => ({excerpt: string}))}
    defaultValue={props.original.excerpt}
    size="small"
    label="Excerpt"
    multiline
    rows={3}
    fullWidth
  />
)

const InternalTagsField = (props: ContentEditPageState) => (
  <TextField
    onChange={props.handleStringSetChange(set => ({internalTags: set}))}
    defaultValue={props.original.internalTags?.join(", ")}
    size="small"
    label="Internal tags"
    fullWidth
  />
);

const NotesField = (props: ContentEditPageState) => (
  <TextField
    onChange={props.handleStringChange(string => ({notes: string}))}
    defaultValue={props.original.notes}
    size="small"
    label="Notes"
    multiline
    rows={3}
    fullWidth
  />
);

const FeaturedImageIdField = (props: ContentEditPageState) => {
  return (
    <TextField
      onChange={props.handleStringChange(string => ({featuredImageId: string}))}
      defaultValue={props.original.featuredImageId}
      size="small"
      label="Featured Image Id"
      fullWidth
    />);
};

const AuthorIdField = (props: ContentEditPageState) => {
  return (
    <TextField
      onChange={props.handleStringChange(string => ({authorId: string}))}
      defaultValue={props.original.authorId}
      size="small"
      label="Author Id"
      fullWidth
    />);
};

const DirectoryField = (props: ContentEditPageState) => {
  return (
    <TextField
      onChange={props.handleStringChange(string => ({directory: string}))}
      defaultValue={props.original.directory}
      size="small"
      label="Directory"
      fullWidth
    />);
};

const SlugField = (props: ContentEditPageState) => {
  return (
    <TextField
      onChange={props.handleStringChange(string => ({slug: string}))}
      defaultValue={props.original.slug}
      size="small"
      label="Slug"
      fullWidth
    />);
};

const MetaTitleField = (props: ContentEditPageState) => {
  return (
    <TextField
      onChange={props.handleStringChange(string => ({metaTitle: string}))}
      defaultValue={props.original.metaTitle}
      placeholder="Defaults to document title"
      size="small"
      label="Meta Title"
      fullWidth
    />);
};

const MetaDescriptionField = (props: ContentEditPageState) => {
  return (
    <TextField
      onChange={props.handleStringChange(string => ({metaDescription: string}))}
      defaultValue={props.original.metaDescription}
      size="small"
      label="Meta description"
      multiline
      rows={3}
      fullWidth
    />);
};

const JsonLdField = (props: ContentEditPageState) => {
  return (
    <TextField
      onChange={props.handleStringChange(string => ({jsonLd1: string}))}
      defaultValue={props.original.jsonLd1}
      size="small"
      label="Json Ld"
      multiline
      rows={3}
      fullWidth
    />);
};
import Models from "./ContentModels"
import {simpleGetListApi, simplePostCommandApi} from "../../common/api/SimpleApis";

const ContentApi = {
  listContents        : simpleGetListApi    <                                   Models.ContentState>("/contents/list"),
  archiveContent      : simplePostCommandApi<Models.ArchiveContentCommand,      Models.ContentState>("/contents/archive"),
  createAiDescription : simplePostCommandApi<Models.CreateAiDescriptionCommand, Models.ContentState>("/contents/generate-desc"),
  publishContent      : simplePostCommandApi<Models.PublishContentCommand,      Models.ContentState>("/contents/publish"),
  unarchiveContent    : simplePostCommandApi<Models.UnarchiveContentCommand,    Models.ContentState>("/contents/unarchive"),
  unpublishContent    : simplePostCommandApi<Models.UnpublishContentCommand,    Models.ContentState>("/contents/unpublish"),
  updateContent       : simplePostCommandApi<Models.UpdateContentCommand,       Models.ContentState>("/contents/update"),
}

export default ContentApi

import TaskModels from "./TaskModels"
import {simpleGetListApi, simplePostCommandApi} from "../../common/api/SimpleApis";

const TaskApi = {
  listTasks     : simpleGetListApi    <                                 TaskModels.TaskState>("/tasks/list"),
  createTask    : simplePostCommandApi<TaskModels.CreateTaskCommand,    TaskModels.TaskState>("/tasks/create"),
  updateTask    : simplePostCommandApi<TaskModels.UpdateTaskCommand,    TaskModels.TaskState>("/tasks/update"),
  markDoneTask  : simplePostCommandApi<TaskModels.MarkDoneTaskCommand,  TaskModels.TaskState>("/tasks/mark-done"),
  markTodoTask  : simplePostCommandApi<TaskModels.MarkTodoTaskCommand,  TaskModels.TaskState>("/tasks/mark-todo"),
  archiveTask   : simplePostCommandApi<TaskModels.ArchiveTaskCommand,   TaskModels.TaskState>("/tasks/archive"),
  unarchiveTask : simplePostCommandApi<TaskModels.UnarchiveTaskCommand, TaskModels.TaskState>("/tasks/unarchive"),
}

export default TaskApi
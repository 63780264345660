import TaskModels from "../api/TaskModels"
import {IconButton, Paper, Stack, Theme, Typography} from "@mui/material"
import ScheduleIcon from "@mui/icons-material/Schedule"
import React from "react"
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import Box from "@mui/material/Box"
import {TaskStore} from "../TaskStoreProvider"
import {Snackbar} from "../../common/SnackbarProvider"
import EditNoteIcon from '@mui/icons-material/EditNote'
import "././TaskListItem.css"
import {UserAvatar} from "../../common/component/UserAvatar";
import {EditTaskDialog} from "./EditTaskDialog";

type TaskListItemProps = {
  task       : TaskModels.TaskState,
  taskStore  : TaskStore,
  snackBar   : Snackbar,
  currentDay : string,
}

export const TaskListItem = (props: TaskListItemProps) => {
  const sx = (theme: Theme) => ({
    p: "6px",
    backgroundColor: isTaskLate(props) ? theme.palette.background.error : theme.palette.background.paper,
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: theme.palette.background.paper,
    "&:hover": { borderColor: theme.palette.grey[200] },
  })

  return (
    <Paper elevation={0} sx={sx} className="TaskListItem">
        <Stack>
          <TaskListItemHeader {...props}/>
          <TaskListItemBody   {...props}/>
          <TaskListItemFooter {...props}/>
      </Stack>
    </Paper>
  )
}

const TaskListItemHeader = (props: TaskListItemProps) => {

  return (
    <Stack direction="row">
      <TaskStatusCheckbox {...props}/>
      <Typography variant="body1" mt="6px" fontWeight="bolder" lineHeight="1.35rem">
        { props.task.isArchived
          ? <s>{props.task.title}</s>
          : <span>{props.task.title}</span>
        }

      </Typography>
      <Box flexGrow={1}/>
      <TaskEditButton {...props}/>
    </Stack>
  )
}

const TaskListItemBody = (props: TaskListItemProps) => {
  return (
    <Typography variant="body2" sx={{lineHeight: "1.4em", mt: "8px"}}>
      { props.task.isArchived
        ? <s>{props.task.description}</s>
        : <span>{props.task.description}</span>
      }
    </Typography>
  )
}

const TaskListItemFooter = (props: TaskListItemProps) => {
  const dueColor = isTaskLate(props) ? "error" : undefined

  return (
    <Stack mt="8px" direction="row" justifyContent="space-between" alignItems="center">
      { props.task.dueDate !== undefined
        ? (
          <Stack direction="row" alignItems="center">
            <ScheduleIcon sx={{mr: "4px", fontSize: "13px"}} color={dueColor}/>
            <Typography variant="body2" fontSize="13px" color={dueColor}>{props.task.dueDate}</Typography>
          </Stack>
        )
        : <div></div>
      }
      <UserAvatar username={props.task.assignedUsername}/>
    </Stack>
  )
}

const TaskStatusCheckbox = (props: TaskListItemProps) => {
  const handleClick = () => {
    if (props.task.status === "todo") {
      props.taskStore
        .markDoneTask({taskId: props.task.id})
        .then(_ => {
          props.snackBar.showSuccess("You marked the task as done.")
        })
        .catch(_ => {
          props.snackBar.showError("Failed to mark the task as done.")
        })
    } else {
      props.taskStore
        .markTodoTask({taskId: props.task.id})
        .then(_ => {
          props.snackBar.showSuccess("You marked the task as todo.")
        })
        .catch(_ => {
          props.snackBar.showError("Failed to mark the task as todo.")
        })
    }
  }

  return (
    <div>
      <IconButton disabled={props.task.isArchived} size="small" onClick={handleClick} disableRipple sx={{ml: "-8px"}}>
        { props.task.status === "todo"
          ? <CheckBoxOutlineBlankIcon fontSize="medium"/>
          : <CheckBoxIcon fontSize="medium"/>
        }
      </IconButton>
    </div>
  )
}

const TaskEditButton = (props: TaskListItemProps) => {
  const [isEditDialogOpen, setIsEditDialogOpen] = React.useState(false)

  const handleClick = () => {
    setIsEditDialogOpen(true)
  }

  return (
    <div style={{ width: "30px"}}>
      <IconButton size="small" onClick={handleClick} color="primary" className="TaskEditButton">
        <EditNoteIcon fontSize="small" sx={{opacity: 0.7}}/>
      </IconButton>
      {/* fixme this dialog shouldn't be here for all buttons.. */}
      <EditTaskDialog isOpen={isEditDialogOpen} setIsOpen={setIsEditDialogOpen} task={props.task}/>
    </div>
  )
}

const isTaskLate = (props: TaskListItemProps) => {
  return props.task.dueDate !== undefined && props.task.dueDate <= props.currentDay
}

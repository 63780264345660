import Grid from "@mui/material/Unstable_Grid2";
import React from "react";
import {TextField as MuiTextField} from "@mui/material";

export function TextField(
  props: {
    xs         : number,
    value      : string,
    label      : string,
    onChange   : (newValue: string) => void,
    multiline? : boolean,
    rows?      : number,
  }
) {
  return (
    <Grid xs={props.xs}>
      <MuiTextField
        label={props.label}
        value={props.value}
        size="small"
        onChange={e => props.onChange(e.target.value)}
        fullWidth
        multiline={props.multiline}
        rows={props.rows}
      />
    </Grid>
  )
}
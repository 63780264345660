import React from "react"
import WritingApi from "../../scenes/articles/WritingApi"
import {useAuth} from "../../providers/AuthProvider"
import {Dictionary} from "lodash"
import WritingModels from "../../scenes/articles/WritingModels"
import {StoreUtils} from "../../common/StoreUtils"
import {DaoTemplate} from "../../common/dao/DaoTemplate"
import useDao = DaoTemplate.useDao
import initOrUpdateStoreState = StoreUtils.initOrUpdateStoreState

const ArticleStoreContext = React.createContext<ArticleStore>(null!)
export function useArticleStore() {
  return React.useContext(ArticleStoreContext)
}

interface ArticleStore {
  articlesById       : Dictionary<WritingModels.Article>,
  updateArticle      : (_: WritingModels.UpdateArticleCommand)      => Promise<void>,
  deleteArticle      : (_: WritingModels.DeleteArticleCommand)      => Promise<void>,
  createArticle      : (_: WritingModels.CreateArticleCommand)      => Promise<void>,
  importKeywordSheet : (_: WritingModels.ImportKeywordSheetCommand) => Promise<void>,
}

type State = StoreUtils.LocalState<WritingModels.Article>

export function ArticleStoreProvider({ children }: { children: React.ReactNode }) {
  const auth              = useAuth()
  const writingDao        = useDao<WritingModels.Article>("writing")
  const [state, setState] = React.useState<State>()

  React.useEffect(() => {
    initOrUpdateStoreState<WritingModels.Article>({
      auth          : auth,
      dao           : writingDao,
      listApi       : WritingApi.listArticlesApi,
      storeState    : state,
      setStoreState : setState,
    })
  }, [state, setState, auth, writingDao])

  const contextValue = {
    articlesById       : state?.itemsById || {},
    updateArticle      : StoreUtils.useCallApiFn(WritingApi.updateArticleApi,    state, setState),
    deleteArticle      : StoreUtils.useCallApiFn(WritingApi.deleteArticleApi,    state, setState),
    createArticle      : StoreUtils.useCallApiFn(WritingApi.createArticleApi,    state, setState),
    importKeywordSheet : StoreUtils.useCallApiNFn(WritingApi.importKeywordSheet, state, setState),
  }

  return (
    <ArticleStoreContext.Provider value={contextValue}>
      {children}
    </ArticleStoreContext.Provider>
  )
}

import {useTaskStore} from "../../task/TaskStoreProvider"
import {Badge} from "@mui/material";
import AssignmentIcon from "@mui/icons-material/Assignment";
import Box from "@mui/material/Box";

export const TaskBadge = (props: {contentId: string} | {writingId: string}) => {
  const taskStore = useTaskStore()

  const tasks = "contentId" in props
    ? taskStore.tasksByContentId[props.contentId]
    : taskStore.tasksByWritingId[props.writingId]

  const toDoCount = tasks?.filter(task => task.status === "todo" && !task.isArchived).length

  return (
    <Box sx={{ width: "30px", pt: "4px"}}>
      { toDoCount
        ?
          <Badge badgeContent={toDoCount} color="warning" sx={{mr: "6px"}}>
            <AssignmentIcon color="action" />
          </Badge>
        : <></>
      }
    </Box>
  )
}
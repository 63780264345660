import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack} from "@mui/material"
import React from "react"
import {useContentStore} from "../../../ContentStoreProvider"
import Models from "../../../api/ContentModels"
import {useSnackbar} from "../../../../common/SnackbarProvider"
import Typography from "@mui/material/Typography"
import {CancelButton} from "../../../../common/component/Buttons"

export function ArchiveContentDialog(props: {
  contentId: string,
  isOpen: boolean,
  setIsOpen: (_: boolean) => void,
}) {
  const {archiveContent} = useContentStore()
  const snackbar = useSnackbar()

  const handleArchive = () => {

    const command: Models.ArchiveContentCommand = {
      contentId: props.contentId,
    }

    archiveContent(command)
      .then(_ => {
        snackbar.showSuccess("Content is successfully archived")
        props.setIsOpen(false)
      })
      .catch(error => {
        snackbar.showError(`Error: ${error.message}`)
      })
  }

  const handleCancel = () => {
    props.setIsOpen(false)
  }

  return (
    <Dialog fullWidth open={props.isOpen} maxWidth="sm" onClose={handleCancel}>
      <DialogTitle>Archive Content</DialogTitle>
      <DialogContent>
        <Typography variant="subtitle1">Do you really want to archive this article?</Typography>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" spacing={2}>
          <CancelButton
            tooltip=""
            onClick={handleCancel}/>
          <Button
            variant="contained"
            onClick={handleArchive}
          >Archive</Button>
        </Stack>
      </DialogActions>
    </Dialog>
  )
}
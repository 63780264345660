import React from "react";
import {IDBPDatabase, openDB} from "idb";

export function useDb() {
  return React.useContext(DbContext);
}

const DATABASE_NAME = "BECCHI_DB";
const DATABASE_VERSION = 1;

export type StoreName = "contents" | "writing" | "tasks"

export type DB = IDBPDatabase;

export function DbProvider({children}: { children: React.ReactNode }) {
  const [db, setDb] = React.useState<IDBPDatabase>();

  React.useEffect(() => {
    if (db === undefined) {
      openDatabase()
        .then(db => {
          console.debug(`Database connection opened to ${db.name}`);
          setDb(db);
        })
        .catch(error => {
          console.error("Couldn't open database", error);
        });
    } else {
      return () => {
        console.debug(`Database connection closed to ${db.name}`);
        db.close();
      }
    }
  }, [db, setDb]);

  return db === undefined
    ? <></>
    : <DbContext.Provider value={{db}}>
        {children}
      </DbContext.Provider>
    ;
}

function openDatabase() {
  return openDB(DATABASE_NAME, DATABASE_VERSION, {
    upgrade(db) {
      db.createObjectStore("contents", { keyPath: "id" })
      db.createObjectStore("tasks",    { keyPath: "id" })
      db.createObjectStore("writing",  { keyPath: "id" })
      db.createObjectStore("update-timestamps")
    },
  })
}

const DbContext = React.createContext<DbContextType>(null!);

interface DbContextType {
  db: DB,
}
import React from "react"
import TaskApi from "./api/TaskApi"
import TaskModels from "./api/TaskModels"
import {Dictionary, groupBy} from "lodash"
import {StoreUtils} from "../common/StoreUtils"
import {DaoTemplate} from "../common/dao/DaoTemplate"
import {useAuth} from "../providers/AuthProvider"
import useDao = DaoTemplate.useDao
import initOrUpdateStoreState = StoreUtils.initOrUpdateStoreState

const TaskStoreContext = React.createContext<TaskStore>(null!)
export function useTaskStore() {
  return React.useContext(TaskStoreContext)
}

export type TaskStore = {
  tasksById        : Dictionary<TaskModels.TaskState>,
  tasksByContentId : Dictionary<ReadonlyArray<TaskModels.TaskState>>,
  tasksByWritingId : Dictionary<ReadonlyArray<TaskModels.TaskState>>,
  createTask       : (_: TaskModels.CreateTaskCommand)    => Promise<void>,
  updateTask       : (_: TaskModels.UpdateTaskCommand)    => Promise<void>,
  archiveTask      : (_: TaskModels.ArchiveTaskCommand)   => Promise<void>,
  unarchiveTask    : (_: TaskModels.UnarchiveTaskCommand) => Promise<void>,
  markDoneTask     : (_: TaskModels.MarkDoneTaskCommand)  => Promise<void>,
  markTodoTask     : (_: TaskModels.MarkTodoTaskCommand)  => Promise<void>,
}

type State = StoreUtils.LocalState<TaskModels.TaskState>

export function TaskStoreProvider({children}: { children: React.ReactNode }) {
  const auth              = useAuth()
  const taskDao           = useDao<TaskModels.TaskState>("tasks")
  const [state, setState] = React.useState<State>()

  React.useEffect(() => {
    initOrUpdateStoreState<TaskModels.TaskState>({
      auth          : auth,
      dao           : taskDao,
      listApi       : TaskApi.listTasks,
      storeState    : state,
      setStoreState : setState,
    })
  }, [state, setState, auth, taskDao])

  const contextValue: TaskStore = {
    tasksById        : state?.itemsById || {},
    tasksByContentId : toTasksByContentId(state?.itemsById),
    tasksByWritingId : toTasksByWritingId(state?.itemsById),
    createTask       : StoreUtils.useCallApiFn(TaskApi.createTask,    state, setState),
    updateTask       : StoreUtils.useCallApiFn(TaskApi.updateTask,    state, setState),
    archiveTask      : StoreUtils.useCallApiFn(TaskApi.archiveTask,   state, setState),
    unarchiveTask    : StoreUtils.useCallApiFn(TaskApi.unarchiveTask, state, setState),
    markDoneTask     : StoreUtils.useCallApiFn(TaskApi.markDoneTask,  state, setState),
    markTodoTask     : StoreUtils.useCallApiFn(TaskApi.markTodoTask,  state, setState),
  }

  return (
    <TaskStoreContext.Provider value={contextValue}>
      {children}
    </TaskStoreContext.Provider>
  )
}

function toTasksByContentId(tasksById: undefined | Dictionary<TaskModels.TaskState>) {
  return tasksById
    ? groupBy(Object.values(tasksById).filter(task => task.contentId !== undefined), task => task.contentId!)
    : {}
}

function toTasksByWritingId(tasksById: undefined | Dictionary<TaskModels.TaskState>) {
  return tasksById
    ? groupBy(Object.values(tasksById).filter(task => task.writingId !== undefined), task => task.writingId!)
    : {}
}

import {DB, StoreName, useDb} from "../DbProvider"
import React from "react"

export namespace DaoTemplate {
  const UPDATE_TIMESTAMP_STORE_NAME = "update-timestamps"

  export type HasId = {
    id: string,
  }

  export interface Dao<I> {
    getAllItems     : ()                        => Promise<ReadonlyArray<I>>
    putAllItems     : (items: ReadonlyArray<I>) => Promise<void>
    getSnapshotTime : ()                        => Promise<number | undefined>
    putSnapshotTime : (snapshotTime: number)    => Promise<void>
  }

  export function useDao<I extends HasId>(
    storeName : StoreName
  ): Dao<I> {
    const {db} = useDb()

    return React.useMemo(() => {
      console.debug(`Creating Dao for ${storeName}`)

      const getAllItems     = ()                        => getAll<I>(db, storeName)
      const putAllItems     = (items: ReadonlyArray<I>) => putAll<I>(db, storeName, items)
      const getSnapshotTime = ()                        => getSnapshotTimeForKey(db, storeName)
      const putSnapshotTime = (snapshotTime: number)    => putSnapshotTimeForKey(db, storeName, snapshotTime)

      return ({
        getAllItems,
        putAllItems,
        getSnapshotTime,
        putSnapshotTime,
      })
    }, [db, storeName])
  }

  function getAll<I>(db: DB, storeName: StoreName): Promise<ReadonlyArray<I>> {
    const tx = db.transaction([storeName], "readonly")
    const store = tx.objectStore(storeName)

    return (
      store
        .getAll()
        .then(results => results as ReadonlyArray<I>)
        .finally(() => {
          return tx.done
        })
      )
  }

  function putAll<I>(db: DB, storeName: StoreName, items: ReadonlyArray<I>): Promise<void> {
    const tx    = db.transaction([storeName], "readwrite")
    const store = tx.objectStore(storeName)

    return (
      Promise
        .all(items.map(i => store.put(i)))
        .then(() => {})
        .finally(() => {
          return tx.done
        })
    )
  }

  function getSnapshotTimeForKey(db: DB, key: string): Promise<number | undefined> {
    const tx = db.transaction([UPDATE_TIMESTAMP_STORE_NAME], "readonly")
    const updateTimestampStore = tx.objectStore(UPDATE_TIMESTAMP_STORE_NAME)

    return (
      updateTimestampStore
        .get(key)
        .finally(() => {
          return tx.done
        })
    )
  }

  function putSnapshotTimeForKey(db: DB, key: string, snapshotTime: number): Promise<void> {
    const tx = db.transaction([UPDATE_TIMESTAMP_STORE_NAME], "readwrite")
    const updateTimestampStore = tx.objectStore(UPDATE_TIMESTAMP_STORE_NAME)

    return (
      updateTimestampStore.put(snapshotTime, key)
        .then(() => {})
        .finally(() => {
          return tx.done
        })
    )
  }
}
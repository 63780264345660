import {simpleGetListApi, simplePostCommandApi} from "../../common/api/SimpleApis";
import WritingModels from "./WritingModels";

const WritingApi = {
  listArticlesApi    : simpleGetListApi<WritingModels.Article>("/writing-tasks/list"),
  importKeywordSheet : simplePostCommandApi<WritingModels.ImportKeywordSheetCommand, ReadonlyArray<WritingModels.Article>>("/writing-tasks/import"),
  updateArticleApi   : simplePostCommandApi<WritingModels.UpdateArticleCommand, WritingModels.Article>("/writing-tasks/update"),
  deleteArticleApi   : simplePostCommandApi<WritingModels.DeleteArticleCommand, WritingModels.Article>("/writing-tasks/delete"),
  createArticleApi   : simplePostCommandApi<WritingModels.CreateArticleCommand, WritingModels.Article>("/writing-tasks/create"),
}

export default WritingApi
import Models from "../api/ContentModels"
import {Chip, IconButton, Stack, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material"
import {Link as RouterLink, Link} from "react-router-dom"
import React from "react"
import EditIcon from '@mui/icons-material/Edit'
import {useWebsiteStore} from "../../website/WebsiteStoreProvider"
import {keyBy} from "lodash"
import {Website} from "../../website/WebsiteModels"
import LinkIcon from '@mui/icons-material/Link'
import {useSnackbar} from "../../common/SnackbarProvider"
import {toAgeString} from "../../common/time/DateUtils"
import {TaskBadge} from "../../common/component/TaskBadge"

export function AllContentsTable(props: {
  contentsToDisplay: ReadonlyArray<Models.ContentState>,
}) {
  const {websites} = useWebsiteStore()
  const websiteConfigsByName = keyBy(websites, w => w.websiteName)

  return (
    <Table size="small">
      <TableHead>
        <AllContentHeader/>
      </TableHead>
      <TableBody>
        { props.contentsToDisplay.map(content => <AllContentRow key={content.id} content={content} website={websiteConfigsByName[content.websiteName]}/>) }
      </TableBody>
    </Table>
  )
}

function AllContentHeader() {
  return (
    <TableRow>
      <TableCell align="left"   sx={{width: "20rem"}}>Title</TableCell>
      <TableCell align="center" sx={{width:  "7rem"}}>Status</TableCell>
      <TableCell align="right"  sx={{width:  "7rem"}}>Website</TableCell>
      <TableCell align="right"  sx={{width:  "9rem"}}>Last Modified</TableCell>
      <TableCell align="right"  sx={{width:  "9rem"}}>Published</TableCell>
      <TableCell align="right"  sx={{width:  "7rem"}}>Sponsored?</TableCell>
      <TableCell align="right"  sx={{width:  "7rem"}}>Featured?</TableCell>
      <TableCell align="right"  sx={{width:  "7rem"}}>Ai?</TableCell>
      <TableCell align="right"  sx={{width:  "3rem"}}/>
    </TableRow>
  )
}

function AllContentRow(props: {content: Models.ContentState, website: Website}) {
  const snackBar = useSnackbar()

  const content = props.content
  const website = props.website

  const contentUrl = `${website.websiteUrl}/${content.directory}/${content.slug}/`
  const onCopyLinkClick = async () => {
    await navigator.clipboard.writeText(contentUrl)
    snackBar.showSuccess("Link copied to clipboard")
  }

  return (
    <TableRow sx={{height: "47px"}}>
      <TableCell align="left" style={{maxWidth: "18rem"}}>
        <Stack direction="row" alignItems="center" spacing={1}>
          <IconButton size="small" onClick={onCopyLinkClick}><LinkIcon fontSize="small"/></IconButton>
          <RouterLink style={{maxWidth: "18rem", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap"}} to={contentUrl} title={content.documentTitle} target="_blank">{content.documentTitle}</RouterLink>
        </Stack>
      </TableCell>
      <TableCell align="center">{contentStatusChip(content)}</TableCell>
      <TableCell align="right">{content.websiteName}</TableCell>
      <TableCell align="right">{toAgeString(new Date(content.lastUpdated), new Date())}</TableCell>
      <TableCell align="right">{content.publishTime ? new Date(content.publishTime).toISOString().substring(0, 10) : ""}</TableCell>
      <TableCell align="right">{content.isSponsored ? "Sponsored" : ""}</TableCell>
      <TableCell align="right">{content.isFeatured  ? "Featured" : ""}</TableCell>
      <TableCell align="right">{content.isAiArticle  ? "Ai" : ""}</TableCell>
      <TableCell align="right">
        <Stack direction="row">
          <TaskBadge contentId={content.id}/>
          <IconButton size="small" component={Link} to={`/contents/${content.id}/edit`}><EditIcon fontSize="small"/></IconButton>
        </Stack>
      </TableCell>
    </TableRow>
  )
}

function contentStatusChip(content: Models.ContentState) {
  const contentStatus = contentStatusString(content)
  switch(contentStatus) {
    case "Draft"     : return <Chip size="small" sx={{width: '6rem'}} label="Draft" color="warning"/>
    case "Scheduled" : return <Chip size="small" sx={{width: '6rem'}} label="Scheduled" color="info"/>
    case "Published" : return <Chip size="small" sx={{width: '6rem'}} label="Published" color="success"/>
    case "Removed"   : return <Chip size="small" sx={{width: '6rem'}} label="Removed" color="error"/>
  }
}

function contentStatusString(content: Models.ContentState) {
  if (content.publishTime === undefined) {
    return "Draft"
  } else if (content.publishTime > Date.now()) {
    return "Scheduled"
  } else {
    if (content.doNotPublish) {
      return "Removed"
    } else {
      return "Published"
    }
  }
}
import axios, {AxiosResponse} from "axios";
import {HOST} from "../../Constants";

/**
 * Helper function to send commands to an api end point, and receive an updated state.
 */
export function simplePostCommandApi<C, S>(urlPath : string) {
  // fixme access token should not be undefined..
  return async (accessToken : string | undefined, command: C) => {
    const headers = { headers: { 'Authorization': `Bearer ${accessToken}` }}

    return axios.post<S>(`${HOST}${urlPath}`, command, headers)
      .then(response => response.data)
      .catch(rethrowWithErrorMessage)
  }
}

export function simpleGetListApi<S extends ResponseItem>(urlPath : string) {
  // fixme access token should not be undefined..
  return async (accessToken : string | undefined, snapshotTime : number | undefined) => {
    let allItems        : Array<S> = []
    let newSnapshotTime : undefined | number = undefined
    let updatedSince    : undefined | number = snapshotTime
    let afterItemId     : undefined | string = undefined
    let isTruncated     : undefined | boolean = undefined

    do {
      const response: ListBatchResponse<S> = await listBatch<S>(`${HOST}${urlPath}`, accessToken, updatedSince, afterItemId)
      newSnapshotTime = response.snapshotTime
      isTruncated = response.isTruncated
      if (response.items.length > 0) {
        const lastItem =  response.items[response.items.length - 1]
        allItems = allItems.concat(response.items)
        updatedSince = lastItem.lastUpdated
        afterItemId = lastItem.id
      }
    } while (isTruncated)

    return {
      items        : allItems,
      snapshotTime : newSnapshotTime,
    }
  }
}

async function listBatch<S>(
  urlPath      : string,
  accessToken  : undefined | string,
  snapshotTime : undefined | number,
  afterItemId  : undefined | string,
) {
  const requestConfig = {
    params  : { updatedSince: snapshotTime, afterItemId: afterItemId },
    headers : { 'Authorization': `Bearer ${accessToken}` },
  }

  return axios.get<ListBatchResponse<S>>(urlPath, requestConfig)
    .then(extractData)
    .catch(rethrowWithErrorMessage)
}

function extractData<T>(response: AxiosResponse<T>): T {
  if (response.data) {
    return response.data;
  } else {
    throw new Error("There is no data available in the response");
  }
}

function rethrowWithErrorMessage(error: any): never {
  if (error.response?.data?.error) {
    throw new Error(error.response.data.error)
  } else {
    throw error
  }
}

export type ListResponse<I> = {
  snapshotTime : number,
  items        : ReadonlyArray<I>,
}

type ListBatchResponse<I> = ListResponse<I> & {
  isTruncated  : undefined | boolean,
}

type ResponseItem = {
  id          : string,
  lastUpdated : number,
}
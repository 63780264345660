import Grid from "@mui/material/Unstable_Grid2";
import {FormControl, InputLabel} from "@mui/material";
import React from "react";
import {Select as MuiSelect} from "@mui/material/";

export function Select(
  props: {
    xs       : number,
    label    : string,
    value    : string,
    onChange : (newValue: string) => void,
    children : React.ReactNode,
  }
) {
  return (
    <Grid xs={props.xs}>
      <FormControl fullWidth size="small">
        <InputLabel>{props.label}</InputLabel>
        <MuiSelect<string>
          label={props.label}
          value={props.value}
          onChange={e => props.onChange(e.target.value)}
        >{props.children}</MuiSelect>
      </FormControl>
    </Grid>)
}
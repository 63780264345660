import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Radio,
  Stack, TextField
} from "@mui/material"
import React from "react"
import {useContentStore} from "../../../ContentStoreProvider"
import Models from "../../../api/ContentModels"
import {useSnackbar} from "../../../../common/SnackbarProvider"

export function PublishContentDialog(props: {
  contentId: string,
  isOpen: boolean,
  setIsOpen: (_: boolean) => void,
}) {
  const {publishContent} = useContentStore()
  const snackbar = useSnackbar()

  const [selectedValue, setSelectedValue] = React.useState<"now" | "later">("now")
  const [publishTime, setPublishTime] = React.useState<string | undefined>(undefined)

  const handleRadioButtonChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSelectedValue = event.target.value
    if (newSelectedValue === "now" || newSelectedValue === "later") {
      setSelectedValue(newSelectedValue)
    }
  }

  const handlePublish = () => {
    if (selectedValue === "later" && (publishTime === undefined || isNaN(Date.parse(publishTime)))) {
      return // do nothing
    }

    let publishTimeNumber: number | undefined
    if (selectedValue === "later") {
      if (publishTime!.trim().length === 10) { // publish number is in yyyy-mm-dd format, we should add some time to it
        const hour   = Math.random() > 0.5 ? 16 : 15
        const minute = Math.random() * 60
        const second = Math.random() * 60
        publishTimeNumber = Date.parse(`${publishTime!.trim()} ${hour}:${minute.toFixed()}:${second.toFixed()}`)
      } else {
        publishTimeNumber = Date.parse(publishTime!)
      }
    }

    const command: Models.PublishContentCommand = {
      contentId: props.contentId,
      customPublishTime: selectedValue === "later" ? publishTimeNumber : undefined,
    }
    publishContent(command)
      .then(_ => {
        snackbar.showSuccess("Content is successfully published")
        props.setIsOpen(false)
      })
      .catch(error => {
        snackbar.showError(`Error: ${error.message}`)
      })
  }

  const handleCancel = () => {
    props.setIsOpen(false)
  }

  return (
    <Dialog fullWidth open={props.isOpen} maxWidth="sm">
      <DialogTitle>Publish Content</DialogTitle>
      <DialogContent>
        <Stack>
          <FormControlLabel
            control={<Radio value="now" checked={selectedValue === "now"} onChange={handleRadioButtonChange}/>}
            label="Publish now"/>
          <Grid container>
            <Grid item sm={3}>
              <FormControlLabel
                control={<Radio value="later" checked={selectedValue === "later"} onChange={handleRadioButtonChange}/>}
                label="Publish later"
              />
            </Grid>
            <Grid item sm={9}>
              <TextField
                onClick={() => {
                  selectedValue !== "later" && setSelectedValue("later")
                }}
                onChange={e => setPublishTime(e.target.value)}
                error={publishTime !== undefined && isNaN(Date.parse(publishTime))}
                fullWidth
                size="small"
                label="Publish time"
              />
            </Grid>
          </Grid>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={handleCancel}
        >Cancel</Button>
        <Button
          variant="contained"
          disabled={selectedValue === "later" && publishTime === null}
          onClick={handlePublish}
        >Publish</Button>
      </DialogActions>
    </Dialog>
  )
}
import {Avatar, Typography} from "@mui/material";
import React from "react";

export const UserAvatar = (props: { username: string }) => {
  const lowercaseUsername = props.username.toLowerCase()

  const src = lowercaseUsername === "e" ? "/avatars/e.jpg"
    : lowercaseUsername === "t"  ? "/avatars/t.png"
      : lowercaseUsername === "ai" ? "/avatars/ai.png"
        : undefined

  const nameIcon = props.username.substring(0, 2).toUpperCase()

  return (
    <Avatar sx={{width: "28px", height: "28px"}} alt={props.username} src={src}>
      <Typography fontSize="16px" fontWeight="bold">{nameIcon}</Typography>
    </Avatar>
  )
}
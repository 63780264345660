export type UpdateField<A> = {
  set: A,
}

export function updateString(
  newString: string,
  oldString: string,
): UpdateField<string> | undefined {
  if (newString.trim() === oldString) {
    return undefined
  } else {
    return ({set: newString.trim()})
  }
}

export function updateOptionalString(
  newString: string | undefined,
  oldString: string | undefined,
): UpdateField<string | undefined> | undefined {
  if (newString?.trim() === oldString) {
    return undefined
  } else {
    return ({set: newString?.trim()})
  }
}
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack} from "@mui/material"
import React from "react"
import {useContentStore} from "../../../ContentStoreProvider"
import Models from "../../../api/ContentModels"
import {useSnackbar} from "../../../../common/SnackbarProvider"
import Typography from "@mui/material/Typography"

// fixme change this to use the confirm dialog
export function UnarchiveContentDialog(props: {
  contentId : string,
  isOpen    : boolean,
  setIsOpen : (_: boolean) => void,
}) {
  const {unarchiveContent} = useContentStore()
  const snackbar = useSnackbar()

  const handleUnarchive = () => {

    const command: Models.UnarchiveContentCommand = {
      contentId: props.contentId,
    }

    unarchiveContent(command)
      .then(_ => {
        snackbar.showSuccess("Content is successfully unarchived")
        props.setIsOpen(false)
      })
      .catch(error => {
        snackbar.showError(`Error: ${error.message}`)
      })
  }

  const handleCancel = () => {
    props.setIsOpen(false)
  }

  return (
    <Dialog fullWidth open={props.isOpen} maxWidth="sm" onClose={handleCancel}>
      <DialogTitle>Unarchive Content</DialogTitle>
      <DialogContent>
        <Typography variant="subtitle1">Do you really want to unarchive this article?</Typography>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" spacing={2}>
          <Button
            variant="outlined"
            onClick={handleCancel}
          >Cancel</Button>
          <Button
            variant="contained"
            onClick={handleUnarchive}
          >Unarchive</Button>
        </Stack>
      </DialogActions>
    </Dialog>
  )
}
import {Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Stack,} from "@mui/material"
import React from "react"
import Grid from "@mui/material/Unstable_Grid2"
import {useSnackbar} from "../../common/SnackbarProvider"
import {useTaskStore} from "../TaskStoreProvider"
import TaskModels from "../api/TaskModels"
import {CancelButton, CreateButton} from "../../common/component/Buttons"
import {DatePicker} from "../../common/component/DatePicker"
import {Select} from "../../common/component/Select"
import {TextField} from "../../common/component/TextField"

export function NewTaskDialog(props: {
  isOpen     : boolean,
  setIsOpen  : (b: boolean) => void,
  contentId? : string,
  writingId? : string,
}) {
  const snackBar  = useSnackbar()
  const taskStore = useTaskStore()

  const [title,       setTitle]       = React.useState<string>("")
  const [who,         setWho]         = React.useState<string>("")
  const [dueDate,     setDueDate]     = React.useState<string>()
  const [description, setDescription] = React.useState<string>("")

  const isInvalidForm = (
    title.trim().length < 3 ||
    description.trim().length < 3 ||
    (who !== "e" && who !== "t")
  )

  const clearForm = () => {
    setTimeout(() => {
      setTitle("")
      setWho("")
      setDueDate(undefined)
      setDescription("")
    }, 1500)
  }

  const handleCreate = () => {
    if (!isInvalidForm) {
      const createTaskCommand: TaskModels.CreateTaskCommand = {
        title            : title.trim(),
        assignedUsername : who,
        dueDate          : dueDate,
        description      : description.trim(),
        contentId        : props.contentId,
        writingId        : props.writingId,
      }

      taskStore
        .createTask(createTaskCommand)
        .then(_ => {
          snackBar.showSuccess("Task is successfully created.")
          props.setIsOpen(false)
          clearForm()
        })
        .catch(_ => {
          snackBar.showError("Task couldn't be created.")
        })
    } else {
      // do nothing
    }
  }

  const handleCancel = () => {
    props.setIsOpen(false)
  }

  return (
   <Dialog fullWidth keepMounted maxWidth="sm" disableEscapeKeyDown open={props.isOpen}>
     <DialogTitle>Create new task</DialogTitle>

     <DialogContent>
       <Grid container mt={0} spacing={2}>
         <TextField
           xs={6}
           label="Title"
           value={title}
           onChange={setTitle}
         />

         <Select
           xs={2}
           label="Who"
           value={who}
           onChange={setWho}
         >
           <MenuItem value="">&nbsp;</MenuItem>
           <MenuItem value="e">e</MenuItem>
           <MenuItem value="t">t</MenuItem>
         </Select>

         <DatePicker
           xs={4}
           label="Due (optional)"
           value={dueDate}
           onChange={setDueDate}
         />

         <TextField
           xs={12}
           label="Description"
           value={description}
           onChange={setDescription}
           multiline
           rows={4}
         />
       </Grid>
     </DialogContent>

      <DialogActions>
        <Stack spacing={2} direction="row">

          <CancelButton
            tooltip="Closes this dialog (Esc)"
            onClick={handleCancel}
          />

          <CreateButton
            tooltip={`Creates the task (${ctrlOrCommandButton()} + Enter)`}
            onClick={handleCreate}
            disabled={isInvalidForm}
          />
        </Stack>
      </DialogActions>
    </Dialog>
  )
}

function ctrlOrCommandButton() {
  if (window.navigator.userAgent.includes("Mac")) {
    return "⌘"
  } else {
    return "Ctrl"
  }
}
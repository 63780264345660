import React from "react"
import ContentApi from "./api/ContentApi"
import Models from "./api/ContentModels"
import {Dictionary} from "lodash"
import {useAuth} from "../providers/AuthProvider"
import {StoreUtils} from "../common/StoreUtils"
import {DaoTemplate} from "../common/dao/DaoTemplate"
import useDao = DaoTemplate.useDao
import initOrUpdateStoreState = StoreUtils.initOrUpdateStoreState

const ContentStoreContext = React.createContext<ContentStore>(null!)
export function useContentStore() {
  return React.useContext(ContentStoreContext)
}

export type ContentStore = {
  contentsById        : Dictionary<Models.ContentState>,
  updateContent       : (_: Models.UpdateContentCommand)       => Promise<void>,
  publishContent      : (_: Models.PublishContentCommand)      => Promise<void>,
  unpublishContent    : (_: Models.UnpublishContentCommand)    => Promise<void>,
  createAiDescription : (_: Models.CreateAiDescriptionCommand) => Promise<void>,
  archiveContent      : (_: Models.ArchiveContentCommand)      => Promise<void>,
  unarchiveContent    : (_: Models.UnarchiveContentCommand)    => Promise<void>,
}

type State = StoreUtils.LocalState<Models.ContentState>

export function ContentStoreProvider({children}: { children: React.ReactNode }) {
  const auth              = useAuth()
  const contentDao        = useDao<Models.ContentState>("contents")
  const [state, setState] = React.useState<State>()

  React.useEffect(() => {
    initOrUpdateStoreState<Models.ContentState>({
      auth          : auth,
      dao           : contentDao,
      listApi       : ContentApi.listContents,
      storeState    : state,
      setStoreState : setState,
    })
  }, [state, setState, auth, contentDao])

  const contextValue: ContentStore = {
    contentsById        : state?.itemsById || {},
    updateContent       : StoreUtils.useCallApiFn(ContentApi.updateContent,       state, setState),
    publishContent      : StoreUtils.useCallApiFn(ContentApi.publishContent,      state, setState),
    unpublishContent    : StoreUtils.useCallApiFn(ContentApi.unpublishContent,    state, setState),
    createAiDescription : StoreUtils.useCallApiFn(ContentApi.createAiDescription, state, setState),
    archiveContent      : StoreUtils.useCallApiFn(ContentApi.archiveContent,      state, setState),
    unarchiveContent    : StoreUtils.useCallApiFn(ContentApi.unarchiveContent,    state, setState),
  }

  return (
    <ContentStoreContext.Provider value={contextValue}>
      {children}
    </ContentStoreContext.Provider>
  )
}

import {UpdateField} from "../../common/model/UpdateField";

namespace TaskModels {

  export type CreateTaskCommand = {
    title            : string,
    assignedUsername : string,
    description      : string,
    contentId        : undefined | string,
    writingId        : undefined | string,
    dueDate          : undefined | string,
  }

  export type UpdateTaskCommand = {
    taskId           : string,
    title            : undefined | UpdateField<string>,
    assignedUsername : undefined | UpdateField<string>,
    description      : undefined | UpdateField<string>,
    dueDate          : undefined | UpdateField<string | undefined>,
  }

  export type MarkDoneTaskCommand = {
    taskId : string,
  }

  export type MarkTodoTaskCommand = {
    taskId : string,
  }

  export type ArchiveTaskCommand = {
    taskId : string,
  }

  export type UnarchiveTaskCommand = {
    taskId : string,
  }

  export type TaskListResponse = {
    items:        ReadonlyArray<TaskState>,
    snapshotTime: number,
  }

  export type TaskState = {
    id               : string,
    created          : number,
    lastUpdated      : number,
    title            : string,
    assignedUsername : string,
    description      : string,
    dueDate          : undefined | string,
    contentId        : undefined | string,
    writingId        : undefined | string,
    isArchived       : undefined | boolean,
    status           : undefined | string,
  }
}

export default TaskModels